/* custom-styles.css */

:root {
    /***************************
  --pf-global--FontFamily--sans-serif: "RedhatDisplay", "Tahoma", "Trebuchet MS", Helvetica, sans-serif;
  --pf-global--FontFamily--heading--sans-serif: "Tahoma", "Trebuchet MS", Helvetica, sans-serif;
   *******************************/

  --ory-theme-font-family: "RedHatText", "Overpass", overpass, helvetica, arial, sans-serif !important;
  --ory-theme-font-family-mono: "Courier New" !important;
  --ory-theme-font-style: "normal" !important;
}


/* Add other custom styles as needed */
body .merge-styles {
    --pf-v5-c-page__main-section--BackgroundColor: var(--pf-v5-global--palette--blue-50);
    --pf-v5-c-page__sidebar--BackgroundColor: var(--pf-v5-global--palette--blue-600);
}

body .pf-v5-c-background-image::before {
  filter: none; /* Disable SVG filering on the background image for fullpage pages, login, register, etc. */
}

body .pf-v5-c-about-modal-box {
    background-color: var(--pf-v5-global--palette--cyan-400);
}

/* Make the about box image play nicely. */
body .pf-v5-c-about-modal-box__hero {
	display: block;
	visibility: visible;
	background-image: var(--pf-v5-c-about-modal-box__hero--sm--BackgroundImage);
	background-repeat: no-repeat;
	background-attachment: inherit;
	background-position: var(--pf-v5-c-about-modal-box__hero--sm--BackgroundPosition);
	background-size: contain; /* var(--pf-v5-c-about-modal-box__hero--sm--BackgroundSize); */
    background-position: center;
	grid-area: hero;
}

body .pf-c-about-modal-box {
    background-color: var(--pf-v5-global--palette--cyan-400);
}

body .beta-text {
    color: var(--pf-v5-global--palette--red-100)
}



