.adjustable-sidebar {
  position: fixed;
  top: 75px;
  right: 0;
  bottom: 0;
  width: 500px;
  z-index: 1000;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.sidebar-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.resize-handle {
  position: absolute;
  top: 0;
  left: -10px;
  width: 20px;
  height: 100%;
  cursor: ew-resize;
  background-color: transparent;
}

.resize-handle:hover, .adjustable-sidebar.dragging .resize-handle {
  background-color: rgba(0, 102, 204, 0.1);
}

.resize-handle::before {
  content: '';
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #d1d1d1;
  border-radius: 2px;
}

.resize-handle:hover::before, .adjustable-sidebar.dragging .resize-handle::before {
  background-color: #0066cc;
}

.pf-c-page__main-section {
  transition: margin-right 0.1s ease-out;
}

.sidebar-open .pf-c-page__main-section {
  margin-right: 300px;
}

.tutorial-content ol {
  padding-left: 30px;
  margin-bottom: 20px;
}

.tutorial-content li {
  margin-bottom: 10px;
  padding-left: 10px;
}

.tutorial-content p {
  margin-bottom: 15px;
}

.tutorial-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  padding: 1rem;
  border-bottom: 1px solid #d2d2d2;
}

.tutorial-completion {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #d2d2d2;
}