.pf-c-page__sidebar {
    transition: transform 0.2s ease-out;
  }
  
  .pf-c-page__main {
    transition: margin-left 0.2s ease-out, padding-left 0.2s ease-out;
  }
  
  @media (max-width: 768px) {
    .pf-c-page__main {
      margin-left: 0 !important;
      padding-left: 1rem !important;
    }
  }
  
  .pf-c-nav__link,
  .pf-c-nav__toggle {
    display: flex;
    align-items: center;
  }
  
  .nav-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    margin-right: 16px;
  }
  
  .pf-c-nav__link .nav-icon svg,
  .pf-c-nav__toggle .nav-icon svg {
    width: 16px;
    height: 16px;
  }
  
  .pf-c-nav__link,
  .pf-c-nav__toggle {
    padding-left: 16px;
  }
  
  .pf-c-nav__subnav .pf-c-nav__link {
    padding-left: 56px;
  }
  
  .nav-icon .mui-icon {
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
  }